import { Country } from "@sixty-six-north/i18n"
import { EU, IS, Region, UK, US } from "../i18n/Region"

export enum PrismicLocale {
  uk = "en-gb",
  us = "en-us",
  eu = "eu-en",
  dk = "en-dk",
  is = "is"
}

export const getLocaleForCountry = (country: Country): PrismicLocale => {
  switch (country) {
    case Country.gb:
      return PrismicLocale.uk
    case Country.us:
      return PrismicLocale.us
    case Country.eu:
      return PrismicLocale.eu
    case Country.dk:
      return PrismicLocale.dk
    case Country.is:
      return PrismicLocale.is
    default:
      return PrismicLocale.uk
  }
}

export const getDefaultLocaleForCountry = (country: Country): PrismicLocale => {
  switch (country) {
    case Country.gb:
      return PrismicLocale.uk
    case Country.us:
      return PrismicLocale.uk
    case Country.eu:
      return PrismicLocale.uk
    case Country.dk:
      return PrismicLocale.uk
    case Country.is:
      return PrismicLocale.is
    default:
      return PrismicLocale.uk
  }
}

export const getRegionForLocale = (locale: string): Region | undefined => {
  switch (locale.toLowerCase()) {
    case PrismicLocale.is:
      return IS
    case PrismicLocale.uk:
      return UK
    case PrismicLocale.us:
      return US
    case PrismicLocale.eu:
      return EU
    case PrismicLocale.dk:
      return EU
    default:
      return undefined
  }
}
