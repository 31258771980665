import { FeatureFlagsI } from "../FeatureFlags"
import { GlobalProps } from "../next/GlobalProps"
import { OrderBy } from "../product/ProductDalTypes"
import { CategoryPageProps } from "./CategoryPageParams"
import { PrismicPageProps } from "./PrismicPageParams"
import { ProductPageProps } from "./ProductPageParams"
import { SubCategoryPageProps } from "./SubCategoryPageParams"

export type PageProps = CategoryPageProps &
  SubCategoryPageProps &
  ProductPageProps &
  PrismicPageProps

export interface OrderByProps {
  orderBy: OrderBy
}

export type CatchAllProps = PageProps &
  GlobalProps &
  OrderByProps &
  FeatureFlagsI

export enum PageType {
  PRISMIC = "PRISMIC",
  CATEGORY = "CATEGORY",
  PRODUCT = "PRODUCT",
  ERROR = "ERROR"
}
