//jsxhook
import { GetServerSideProps, GetServerSidePropsContext, NextPage } from "next"
import dynamic from "next/dynamic"
import { ParsedUrlQuery } from "querystring"
import React from "react"
import { DEFAULT_REGION } from "../i18n/Region"
import { StoreResolver } from "../next/StoreResolver"
import { getRegionForLocale, PrismicLocale } from "../prismic/PrismicLocale"
import { CatchAllProps, PageType } from "../routing/PageProps"
import { getServerSidePropsForStore } from "../utils/getServerSidePropsForStore"

export const PageTypeContext = React.createContext<PageType | undefined>(
  undefined
)

const Layout = dynamic(import("../components/Layout"))
const PrismicPage = dynamic(import("../prismic/PrismicPage"))
const ErrorPage = dynamic(import("../components/ErrorPage"))
const CategoryPage = dynamic(import("../category/CategoryPage"))
const ProductPage = dynamic(import("../product/ProductPage"))

export const ParamsPage: NextPage<CatchAllProps> = ({
  categoryPage,
  subCategoryPage,
  productPage,
  prismicPage,
  ...headerProps
}) => {
  let pageType: PageType | undefined = undefined
  let PageElement: React.ReactElement

  if (headerProps.error) {
    pageType = PageType.ERROR
    PageElement = (
      <ErrorPage
        statusCode={headerProps.error.statusCode || 404}
        {...headerProps}
        {...prismicPage}
      />
    )
  } else if (prismicPage && prismicPage.document) {
    pageType = PageType.PRISMIC

    // Avoid displaying Prismic content if Prismic document language is different than the site language
    const { language = DEFAULT_REGION.language } =
      getRegionForLocale(
        prismicPage.document.lang || PrismicLocale[DEFAULT_REGION.country]
      ) || {}

    if (language !== headerProps.region.language) {
      PageElement = <ErrorPage statusCode={404} {...headerProps} />
    } else {
      PageElement = (
        <PrismicPage
          {...prismicPage}
          document={prismicPage.document}
          {...headerProps}
        />
      )
    }
  } else if (categoryPage) {
    pageType = PageType.CATEGORY
    PageElement = <CategoryPage {...categoryPage} {...headerProps} />
  } else if (productPage) {
    pageType = PageType.PRODUCT
    PageElement = <ProductPage {...productPage} {...headerProps} />
  } else {
    pageType = PageType.ERROR
    PageElement = <ErrorPage statusCode={404} {...headerProps} />
  }

  return (
    <PageTypeContext.Provider value={pageType}>
      <Layout minHeight="100vh">{PageElement}</Layout>
    </PageTypeContext.Provider>
  )
}

export const getServerSideProps: GetServerSideProps<CatchAllProps> = async (
  context: GetServerSidePropsContext<ParsedUrlQuery>
) => {
  const store = await StoreResolver.fromServerSideContext(context)
  return getServerSidePropsForStore(store)(context)
}

export default ParamsPage
